import React from "react";
import { Col, Container, Jumbotron, Row, Button } from "react-bootstrap";
export default function PaketLayanans() {
  return (
    <>
      <Jumbotron
        id="header"
        className="bg-color bg-image2 d-flex align-items-center"
      >
        <Container>
          <Row>
            <Col md="8" className="py-4 align-items-denter">
              <h1 className="fw-600" style={{ fontSize: "3rem" }}>
                Mudah dan Praktis buat <br /> website bisnis pertamamu
              </h1>
              <p className="text-respons">
                Kami bantu bisnis anda untuk mempromosikan bisnis, memamerkan
                karya, membuka toko, atau memulai blog
              </p>
              <Row>
                <Col lg={3}>
                  <Button
                    className="btn-contact mb-3 shadow-sm w-100"
                    style={{ padding: "15px 0px", borderRadius: "20px" }}
                  >
                    Contact
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="bg-e8 shadow-sm border-0 btn-category w-100"
                    style={{ padding: "15px 50px", borderRadius: "20px" }}
                  >
                    Categories
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </>
  );
}
