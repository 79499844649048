import React, { useState } from 'react'
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap'

import umImage from '../image-components/project-client/um.png'
import smkn1Image from '../image-components/project-client/smkn1pas.png'
import pemerintahKotaImage from '../image-components/project-client/pemerintah-kota.png'
import vedcImage from '../image-components/project-client/vedc.png'
import agmImage from '../image-components/project-client/agm.png'
import klinikMataImage from '../image-components/project-client/klinik-mata.png'
import hoodwoodImage from '../image-components/project-client/hoodwood.png'
import nasboxImage from '../image-components/project-client/nasbox.png'
import magnaPerkasaImage from '../image-components/project-client/magna-perkasa.png'
import padiImage from '../image-components/project-client/padi.png'
import florenceImage from '../image-components/project-client/florence.png'
// import profile from '../image-components/design-webs/groboxkurir.png'
import Banner from "../main/Banner";

export default function OurProjectClientAc() {
    const [linksImg, setLinksImg] = useState([
        {
            UrlPicture: umImage
        },
        {
            UrlPicture: smkn1Image
        },
        {
            UrlPicture: pemerintahKotaImage
        },
        {
            UrlPicture: vedcImage
        },
        {
            UrlPicture: agmImage
        },
        {
            UrlPicture: klinikMataImage
        },
        {
            UrlPicture: hoodwoodImage
        },
        {
            UrlPicture: nasboxImage
        },
        {
            UrlPicture: magnaPerkasaImage
        },
        {
            UrlPicture: padiImage
        },
        {
            UrlPicture: florenceImage
        },
    ])
    return (
      <>
        <div className="text-center distance">
          <Container>
            <h2 style={{ marginBottom: "40px" }} className="fw-600">
              Client Kami
            </h2>
            <Banner />
            {/* <div
              className="d-flex align-items-center bg-secondary w-100 p-5 mb-5 text-white"
              style={{
                borderRadius: "2rem",
                background: "linear-gradient(to right, #09B6E4, #37D5FF)",
              }}
            >
              <Row className="align-items-center">
                <Col className="text-respons">
                  Kami bantu bisnis Anda untuk mempromosikan bisnis, memamerkan
                  karya, membuka toko, atau memulai blog
                </Col>
                <Col className="d-flex flex-column align-items-center">
                  <Image src={profile} className="rounded-circle" width="50%" />
                  <span style={{ fontSize: "24px" }}>Abdullah, CEO</span>
                  <span style={{ fontSize: "16px" }}>VEDC</span>
                </Col>
              </Row>
            </div> */}
            {/* dekstop */}
            <Row className="justify-content-center d-dekstop2">
              {linksImg.length &&
                linksImg.map((link, idx) => (
                  <Col
                    md={4}
                    lg={2}
                    className="my-4 d-flex justify-content-center align-items-center"
                    key={idx}
                  >
                    <Image src={link.UrlPicture} width="80%" />
                  </Col>
                ))}
            </Row>
            {/* mobile */}
            <Carousel className="d-mobile" indicators={false}>
              {linksImg.length &&
                linksImg.map((link, idx) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-75 mx-auto"
                      src={link.UrlPicture}
                      alt={`first ` + idx}
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          </Container>
        </div>
      </>
    );
}
