import React, { useState } from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
// import gambar from '../image-components/design-webs/Rectangle20.png'
import InfoPortofolioKamiAc from '../main-layanan/InfoPortofolioKamiAc'
import img1 from "../paket-recommend/Rectangle 1.png";
import img2 from "../paket-recommend/Rectangle 2.png";
import img3 from "../paket-recommend/Rectangle 3.png";
import img93 from "../paket-recommend/Rectangle 93.png";

export default function DesignWebsites() {
    const [ecommerce, setEcommerce] = useState([
      {
        id: 1,
        gambar: img93
      },
      {
        id: 2,
        gambar: img3
      },
      {
        id: 3,
        gambar: img2
      },
    ])
    return (
      <>
        <Container className="distance">
          <Row className="justify-content-center text-center mb-5">
            <Col lg={6}>
              <h2 className="fw-600">
                Bingung cari <br /> design buat website kamu
              </h2>
              <p style={{ fontSize: "21px" }}>
                Cari dan temukan konsep design yang sesuai dengan brand dan
                bisnis kamu dengan puluhan template
              </p>
            </Col>
          </Row>
          <Row className="my-4 fw-600">
            <Col xs={12} className="mb-3">
              <h4 className="fw-600">Toko Online</h4>
            </Col>
            {ecommerce.length &&
              ecommerce.map((item) => (
                <Col sm={4} key={item.id} className="mb-4">
                  <Card className="border-0">
                    <Card.Img src={item.gambar} width="250px" />
                    <Card.Body className="p-3">
                      <Card.Text>
                        <a href="#">Lihat Template</a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
          <Row className="my-4 fw-600">
            <Col xs={12} className="mb-3">
              <h4 className="fw-600">Company Profile</h4>
            </Col>
            {ecommerce.length &&
              ecommerce.map((item) => (
                <Col sm={4} key={item} className="mb-4">
                  <Card className="border-0">
                    <Card.Img src={item.gambar} width="250px" />
                    <Card.Body className="p-3">
                      <Card.Text>
                        <a href="#">Lihat Template</a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
          <Row className="my-4 fw-600">
            <Col xs={12} className="mb-3">
              <h4 className="fw-600">Blog</h4>
            </Col>
            {ecommerce.length &&
              ecommerce.map((item) => (
                <Col sm={4} key={item} className="mb-4">
                  <Card className="border-0">
                    <Card.Img src={item.gambar} width="250px" />
                    <Card.Body className="p-3">
                      <Card.Text>
                        <a href="#">Lihat Template</a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
          <InfoPortofolioKamiAc />
        </Container>
      </>
    );
}
