import { faShoppingCart, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState, useEffect} from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoVisx from "./image-components/logo.png";

export default function NavbarAc() {
  const [isActive, setIsActive] = useState(0);
  const [styleNavbar, setStyleNavbar] = useState("fixed-top bg-trans");

  useEffect(() => {
      window.onscroll = () => {
          if (window.pageYOffset >= 600) {
              setStyleNavbar("fixed-top bg-semi-trans shadow");
          } else {
              setStyleNavbar("fixed-top bg-trans");
          }
      }
  }, [])

  
  function setLinkActive(title) {
    if (title === "layanan") {
      setIsActive(1);
    } else if (title === "company") {
      setIsActive(2);
    } else if (title === "templates") {
      setIsActive(3);
    } else {
      setIsActive(0);
    }
  }
  return (
    <Navbar id="navbar" expand="md" className={styleNavbar}>
      <Container>
        <Navbar.Brand href="#home">
          <Image src={logoVisx} className="w-navbar-brand" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* Mobile */}
        <Navbar className="d-mobile">
          <Nav className="navbar-nav ml-auto font-weight-bold">
            <Nav.Link
              className="ml-2 bg-e8"
              style={{ borderRadius: "10px" }}
              href="#home"
            >
              <FontAwesomeIcon
                style={{ color: "#000", fontSize: "18px" }}
                icon={faUser}
              />
            </Nav.Link>
            <Nav.Link
              className="ml-2 bg-e8"
              style={{ borderRadius: "10px" }}
              href="#link"
            >
              <div style={{ position: "relative" }}>
                <FontAwesomeIcon
                  style={{ color: "#000", fontSize: "18px" }}
                  icon={faShoppingCart}
                />
                <span className="d-inline-block rounded-circle shopping-cart-notif"></span>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-nav navbar-toggle margin-auto font-weight-bold justify-content-between">
            <Nav.Link
              className={
                isActive === 0
                  ? "navbar-link active-link shadow px-4 py-2"
                  : "navbar-link"
              }
              onClick={() => setLinkActive("home")}
              as={Link}
              to="/"
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={
                isActive === 3
                  ? "navbar-link active-link shadow px-4 py-2"
                  : "navbar-link"
              }
              onClick={() => setLinkActive("templates")}
              as={Link}
              to="/detail-layanan"
            >
              Templates
            </Nav.Link>
            <Nav.Link
              className={
                isActive === 1
                  ? "navbar-link active-link shadow px-4 py-2"
                  : "navbar-link"
              }
              onClick={() => setLinkActive("layanan")}
              as={Link}
              to="/layanan"
            >
              Layanan
            </Nav.Link>
            <Nav.Link
              className={
                isActive === 2
                  ? "navbar-link active-link shadow px-4 py-2"
                  : "navbar-link"
              }
              onClick={() => setLinkActive("company")}
              as={Link}
              to="/company"
            >
              Company
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {/* dekstop */}
        <Navbar className="d-dekstop">
          <Nav className="navbar-nav ml-auto font-weight-bold ">
            <Nav.Link
              className="ml-2 bg-e8"
              style={{ borderRadius: "10px" }}
              href="#home"
            >
              <FontAwesomeIcon
                style={{ color: "#000", fontSize: "18px" }}
                icon={faUser}
              />
            </Nav.Link>
            <Nav.Link
              className="ml-2 bg-e8"
              style={{ borderRadius: "10px" }}
              href="#link"
            >
              <div style={{ position: "relative" }}>
                <FontAwesomeIcon
                  style={{ color: "#000", fontSize: "18px" }}
                  icon={faShoppingCart}
                />
                <span className="d-inline-block rounded-circle shopping-cart-notif"></span>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar>
      </Container>
    </Navbar>
  );
}
