import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import profile from '../image-components/design-webs/groboxkurir.png'

export default function Banner() {
    return (
      <>
        <div
              className="d-flex align-items-center bg-secondary w-100 p-5 mb-5 text-white"
              style={{
                borderRadius: "2rem",
                background: "linear-gradient(to right, #09B6E4, #37D5FF)",
              }}
            >
              <Row className="flex-md-row-reverse align-items-center">
                <Col className="d-flex flex-column align-items-center">
                  <Image src={profile} className="rounded-circle" width="80%" />
                  <span style={{ fontSize: "24px" }}>Abdullah, CEO</span>
                  <span style={{ fontSize: "16px" }}>VEDC</span>
                </Col>
                <Col md={8} className="text-respons">
                  Kami bantu bisnis Anda untuk mempromosikan bisnis, memamerkan
                  karya, membuka toko, atau memulai blog
                </Col>
              </Row>
            </div>
      </>
    );
}
