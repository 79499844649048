import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import aboutImage from '../image-components/AboutMe.png'

export default function AboutHomeAc() {
    return (
        <>
            <Container className="distance">
                <Row>
                    <Col md={6} className="d-flex justify-content-center align-items-center">
                        <Image src={aboutImage} className="w-about" />
                    </Col>
                    <Col md={6} className="py-4 d-flex flex-column justify-content-center">
                        <h1 className="mb-3 fw-600">
                            Mudah dan praktis buat
                            website bisnis pertamamu
                        </h1>
                        <div>
                            <p className="text-respons">
                                kami bantu bisnis anda untuk mempromosikan
                                bisnis, memamerkan karya, membuka toko,
                                atau memulai blog.
                            </p>
                            <Button className="btn-tanya shadow-sm">Tanya-tanya yuk</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
