import React, { useState } from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
// import rectangle from "../image-components/Rectangle37@2x.png";
import { numberFormat } from "../../components/BaseComponent";
import { Link } from "react-router-dom";
import img1 from "../paket-recommend/Rectangle 1.png";
import img2 from '../paket-recommend/Rectangle 2.png';
import img3 from "../paket-recommend/Rectangle 3.png";

export default function PaketLayananAc() {
  const [paket, setPaket] = useState([
    {
      id: 1,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img1,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 2,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img2,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 3,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img3,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
  ]);
  return (
    <>
      <div className="distance bg-img">
        <Container>
          <h2 className="text-center fw-600 mb-5">
            Atau Kamu Bisa <br />
            Pilih Paket Layanan Dari Kami
          </h2>
          {/* Tabs */}
          <Row>
            {paket.length &&
              paket.map((item, idx) => (
                <Col md={6} lg={4} key={idx}>
                  <Card
                    style={{
                      backgroundColor: "#F9F9F9",
                    }}
                    className="p-3 border-0 br-20px mb-4"
                  >
                    <Card.Img
                      variant="top"
                      src={item.gambar}
                      className="border-none br-20px mb-3"
                    />
                    <Card.Title className="mb-2 p-2">
                      <span className="text-info fw-600">{item.title}</span>
                      <br />
                      <span
                        className="text-secondary"
                        style={{ fontSize: "16px" }}
                      >
                        Harga mulai dari
                      </span>
                      <br />
                      <span style={{ fontSize: "26px", fontWeight: "900" }}>
                        Rp. {numberFormat(item.harga)}
                      </span>
                    </Card.Title>
                    <Card.Text className="p-2 my-4">{item.deskripsi}</Card.Text>
                    <Button
                      className="btn-order py-3 br-20px"
                      style={{ fontSize: "18px" }}
                    >
                      Order Sekarang
                    </Button>
                  </Card>
                </Col>
              ))}
          </Row>
          <Button
            as={Link}
            to="/detail-layanan"
            variant="trans"
            className="mx-auto d-block px-4 py-2 border-0 fw-600 text-info"
            style={{ marginTop: "50px", borderRadius: "10px", fontSize: '21px' }}
          >
            Explore Lebih Banyak
          </Button>
        </Container>
      </div>
    </>
  );
}
