import React from "react";
import { Button, Col, Container, Image, Jumbotron, Row } from "react-bootstrap";
// import imgContent from "../image-components/header/Header2.png";
import titleImg from "../image-components/header/title-jumbroton.png";

export default function HeaderHomeAc() {
  return (
    <>
      <Jumbotron id="header" className="bg-image bg-color">
        <Container>
          <Row>
            <Col md={6} className="position-relative py-4 align-items-denter">
              <Image src={titleImg} width="100%" />
              <p className="text-respons">
                Go online bisnis kamu dan dapat ribuan customer dan exposure
                bisnis mu bersama kami
              </p>
              <Button
                className="btn-contact shadow-sm mb-2 mr-2"
                style={{ padding: "15px 50px", borderRadius: "15px" }}
              >
                Contact
              </Button>
              <Button
                className="bg-e8 shadow-sm border-0 btn-category"
                style={{ padding: "15px 50px", borderRadius: "15px" }}
              >
                Categories
              </Button>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </>
  );
}
