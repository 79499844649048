import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import profile from '../image-components/design-webs/1228.png'
import danang from '../image-components/design-webs/danang.png'

export default function TimKamiAc() {
    return (
      <>
        <div className="distance bg-img">
          <Container>
            <div className="w-why-we text-center mx-auto">
              <h2 className="fw-600">We Make The Best</h2>
              <p style={{ fontSize: "21px" }}>
                Kami bantu bisnis Anda untuk mempromosikan bisnis, memamerkan
                karya, membuka toko, atau memulai blog
              </p>
            </div>
            <Row className="pt-5 justify-content-center">
              <Col
                md={6}
                className="d-flex flex-column justify-content-between"
              >
                <div
                  className="py-3 br-20px text-center text-white text-respons mb-4"
                  style={{
                    background: "linear-gradient(to right, #24D1FF, #18B2FF)",
                  }}
                >
                  Team
                </div>
                <Card
                  className="border-0 p-5 br-20px mx-2 mb-4"
                  style={{ backgroundColor: "#F9F9F9", height: "100%" }}
                >
                  <Card.Img src={profile} className="w-team" />
                  <Card.Title
                    className="fw-600 mt-4 ml-2"
                    style={{ fontSize: "24px" }}
                  >
                    Abdulloh, CEO
                  </Card.Title>
                  <Card.Text
                    className="d-flex flex-column ml-2 text-secondary"
                    style={{ fontSize: "16px" }}
                  >
                    <span>abdullohalhaddad</span>
                    <span>@visxindonesia.com</span>
                  </Card.Text>
                  <Card.Text className="ml-2" style={{ fontSize: "18px" }}>
                    kami berfokus pada kreatifitas dan user friendly untuk
                    memberikan experients terbaik di setiap product yang kami
                    tangani
                  </Card.Text>
                </Card>
              </Col>
              <Col md={6}>
                <Card
                  className="border-0 p-5 br-20px mx-2 mb-4"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <Card.Img src={profile} className="w-team2" />
                  <Card.Title
                    className="fw-600 mb-4 mt-2 ml-2"
                    style={{ fontSize: "22px" }}
                  >
                    Wahyudi, CTO
                  </Card.Title>
                  <Card.Text
                    className="d-flex flex-column ml-2 text-secondary"
                    style={{ fontSize: "14px" }}
                  >
                    <span>wahyudi</span>
                    <span>@visxindonesia.com</span>
                  </Card.Text>
                </Card>
                <Card
                  className="border-0 p-5 br-20px mx-2 mb-4"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <Card.Img src={danang} className="w-team2" />
                  <Card.Title
                    className="fw-600 mb-4 mt-2 ml-2"
                    style={{ fontSize: "22px" }}
                  >
                    Danang Tejo, CPO
                  </Card.Title>
                  <Card.Text
                    className="d-flex flex-column ml-2 text-secondary"
                    style={{ fontSize: "14px" }}
                  >
                    <span>danangkuncoro</span>
                    <span>@visxindonesia.com</span>
                  </Card.Text>
                </Card>
              </Col>
              <Col md={4} className="mb-2">
                <div
                  className="border-0 p-4 br-20px mx-2"
                  style={{ backgroundColor: "#F9F9F9", height: '100%'}}
                ></div>
              </Col>
              <Col md={8}>
                <div
                  className="display-responsive border-0 p-4 br-20px mx-2 "
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <Image src={profile} className="w-team2" />
                  <div
                    className="d-flex flex-column p-4 text-secondary"
                    style={{ fontSize: "16px" }}
                  >
                    <span
                      className="fw-600"
                      style={{ fontSize: "24px", color: "#000" }}
                    >
                      Tajudin, Developer
                    </span>
                    <span>muhammadtajudin</span>
                    <span>@visxindonesia.com</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
}
