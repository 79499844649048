import React, { useState } from "react";
import { Button, Card, Col, Container, Jumbotron, Row } from "react-bootstrap";
import { numberFormat } from "../components/BaseComponent";
import rectangle from "../components/image-components/Rectangle37@2x.png";
import InfoPortofolioKamiAc from "../components/main-layanan/InfoPortofolioKamiAc";
import img1 from "../components/paket-recommend/Rectangle 1.png";
import img2 from "../components/paket-recommend/Rectangle 2.png";
import img3 from "../components/paket-recommend/Rectangle 3.png";
import img93 from "../components/paket-recommend/Rectangle 93.png";

import GetStartedVisx from "../components/main/GetStartedVisx";
export default function DetailPaketLayanan() {
  const [paket, setPaket] = useState([
    {
      id: 1,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img1,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 2,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img2,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 3,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img3,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 4,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img93,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 5,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img1,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 6,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img2,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 7,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img3,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
    {
      id: 8,
      title: "Simple Toko Online",
      harga: 3500000,
      gambar: img93,
      deskripsi:
        "Direkomendasikan untuk bisnis atau UKM yang menjual barang atau jasa kepada konsumen secara langsung seperti bisnis kuliner, properti, konveksi, travel, dll",
    },
  ]);
  return (
    <>
      <Jumbotron id="header" className="bg-color d-flex align-items-end">
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="fw-600" style={{ fontSize: "4rem" }}>
                Atau kamu bisa pilih
                <br /> paket layanan dari kami
              </h1>
            </Col>
            <Col lg={12} className="display-responsive justify-content-between">
              <Button
                style={{
                  fontSize: "160%",
                  background: "linear-gradient(to right, #1F6DE8, #1BDCFF)",
                  border: "none",
                }}
                className="br-20px py-2 w-100 mb-2 mr-2"
              >
                Website
              </Button>
              <Button className="br-20px bg-e8 text-dark btn-templates py-2 w-100 mb-2 mr-2">
                Aplikasi
              </Button>
              <Button className="br-20px bg-e8 text-dark btn-templates py-2 w-100 mb-2 mr-2">
                System informasi
              </Button>
              <Button className="br-20px bg-e8 text-dark btn-templates py-2 w-100 mb-2">
                Template
              </Button>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container className="mb-5">
        <Row>
          {paket.length &&
            paket.map((item, idx) => (
              <Col md={6} lg={4}  className="mb-4" key={idx}>
                <Card
                  style={{
                    backgroundColor: "#F9F9F9",
                  }}
                  className="p-3 border-0 br-20px"
                >
                  <Card.Img
                    variant="top"
                    src={item.gambar}
                    className="border-none br-20px mb-3"
                  />
                  <Card.Title className="mb-2 p-2">
                    <span className="text-info fw-600">{item.title}</span>
                    <br />
                    <span
                      className="text-secondary"
                      style={{ fontSize: "16px" }}
                    >
                      Harga mulai dari
                    </span>
                    <br />
                    <span style={{ fontSize: "26px", fontWeight: "900" }}>
                      Rp. {numberFormat(item.harga)}
                    </span>
                  </Card.Title>
                  <Card.Text className="p-2 my-4">{item.deskripsi}</Card.Text>
                  <Button
                    className="btn-order py-3 br-20px"
                    style={{ fontSize: "18px" }}
                  >
                    Order Sekarang
                  </Button>
                </Card>
              </Col>
            ))}
        </Row>
        <InfoPortofolioKamiAc/>
      </Container>
        <GetStartedVisx />
    </>
  );
}
