import React from 'react'
import { Col, Container, Button, Jumbotron, Row } from 'react-bootstrap'
import growth from '../image-components/growth.png'
import darts from '../image-components/darts.png'

export default function CompanyHeaderAc() {
    return (
      <>
        <Jumbotron
          id="header"
          className="bg-color bg-image2 d-flex align-items-center"
        >
          <Container>
            <Row>
              <Col md="8" className="py-4 align-items-denter">
                <h1 className="fw-600" style={{ fontSize: "4rem" }}>
                  Profesional <br /> and Give the Best
                </h1>
                <p className="text-respons">
                  Kami berikan pelayanan yang terbaik untuk setiap projek anda
                </p>
                <Row>
                  <Col lg={3}>
                    <Button
                      className="btn-contact shadow-sm mb-2 w-100"
                      style={{ padding: "15px 0px", borderRadius: "20px" }}
                    >
                      Contact
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="bg-e8 shadow-sm border-0 btn-category w-100"
                      style={{ padding: "15px 50px", borderRadius: "20px" }}
                    >
                      Categories
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </>
    );
}
